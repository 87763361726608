// Ribbon countdown timer scss

$ribbon-height: 70px;
$ribbon-height-desktop: 50px;
$ribbon-height-expanded: 190px;

.seasonal-ribbon-section {
  display: block;
  width: 100%;
  position: sticky;
  height: $ribbon-height;
  top: 0;
  z-index: 200;
  transition: all 0.3s ease;

  @media(min-width: 1024px) {
    height: $ribbon-height-desktop;
  }

  &__bg {
    position: absolute;
    width: 100%;
    object-fit: cover;
    z-index: 1;
    height: 100% !important;
  }

  .seasonal-ribbon {
    &__container {
      justify-content: center;
      flex-direction: column;
      height: 100%;

      @media(min-width: 1024px) {
        flex-direction: row;
        justify-content: space-between;
        padding: 0 20px;
      }

      @media(min-width: 1130px) {
        padding: 0 var(--page-gutter)
      }
    }

    // countdown styling
    &__countdown-container {
      order: 3;

      @media(min-width: 1024px) {
        width: 180px;
        order: 1;
      }

      .promo-counter {
        position: relative;
        justify-content: space-around;
        width: 100%;

        @media(max-width: 1023px) {
          margin-top: 8px;
          width: 100%;
          justify-content: center;
        }

        &__pulse {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &__image-bg {
          display: block;
          position: absolute;
          z-index: 2;
          left: -22px;
          height: 58px;
          width: 230px;
          max-width: none;

          @media (max-width: 1024px) {
            padding: 10px;
            margin-right: 5px;
            display: none;
          }
        }

        // Pulse effect element
        .pulse-effect {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 210px;
          height: 130px;
          background: rgba(0, 255, 0, 0.4);
          border-radius: 50%;
          transform: translate(-50%, -50%) scale(1);
          z-index: 1;
          animation: pulse-animation 3s infinite;
        }

        @media (max-width: 1024px) {
          .pulse-effect {
            display: none;
          }
        }

        &__bg {
          position: relative;
          border-radius: 3.088px;
          background: #1F5720;
          width: 40px;
          height: 40px;
          z-index: 2;
          font-weight: 600;
          font-size: 14px;
          padding-top: 2px;
        }

        &__text {
          font-size: 11px;
          font-weight: 300;
          color: rgba(255, 255, 255, 0.50);
          position: relative;
          top: -3px;
          left: -1px;
        }
      }
    }

    // countdown styling end

    // text styling
    &__text-container {
      position: relative;
      z-index: 2;
      align-items: baseline;
      order: 1;
      flex-direction: column;
      margin-top: 17px;

      @media(min-width: 1024px) {
        flex-direction: row;
        order: 2;
        margin-top: 0;
      }

      &__text {
        font-size: 16px;
        font-weight: 700;
        margin-right: 3px;
        display: flex;
        align-items: baseline;
        justify-content: center;

        @media(max-width: 1023px) {
          width: 100%;
          text-align: center;
          margin-bottom: 8px;

          &:nth-of-type(1) {
            color: #4de450;
            &::after {
              @include svg-arrow(#fff);
              content: '';
              display: inline-block;
              width: 13px;
              height: 9px;
              position: absolute;
              top: -9px;
              left: 50%;
              transition: transform 0.15s linear;
              transform: translateX(-50%) rotate(180deg);
            }
          }
        }

        &--title {
          cursor: pointer;
        }

        &--big {
          font-size: 21px;
          font-weight: 700;
        }

        &--desktop {
          display: none;
          @media(min-width: 1024px) {
            display: inline;
          }
        }

        &--code {
          border-radius: 8px;
          border: 1px dashed rgba(255, 255, 255, 0.60);
          background: rgba(255, 255, 255, 0.20);
          padding: 5px 16px;
          cursor: pointer;
          color: #4DE450;

          .icon {
            color: #fff;
            position: relative;
            top: 1px;
          }
        }

        &--status {
          font-size: 11px;
        }
      }
    }

    // text styling end

    // btn styling
    &__btn-container {
      position: relative;
      z-index: 2;
      order: 3;
      display: none;

      @media(min-width: 1024px) {
        display: flex;
      }

      &--mobile {
        display: flex;

        @media(min-width: 1024px) {
          display: none!important;
        }
      }

      &__btn {
        padding: 10px 25px !important;
        display: flex;
        align-items: center;

        &:after {
          mask-size: 8px !important;
        }

        &:hover:after {
          background-color: #010101 !important;
        }
      }
    }

    // btn styling end
  }

  @media(max-width: 1023px) {
    // hiding everything until expanded
    .seasonal-ribbon__text-container__text--hide,
    .seasonal-ribbon__btn-container,
    .seasonal-ribbon__countdown-container {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: all 0.1s ease;
      margin: 0;
      padding: 0;
    }

    .seasonal-ribbon__countdown-container {
      width: 0;
    }
  }

  &.is-expanded {
    @media(max-width: 1023px) {
      padding: 0;
      margin: 0;
      height: $ribbon-height-expanded;

      .seasonal-ribbon__container {
        height: auto;
      }
      .seasonal-ribbon__text-container__text--hide,
      .seasonal-ribbon__btn-container,
      .seasonal-ribbon__countdown-container {
        max-height: 60px;
        opacity: 1;
        overflow: visible;
        transition: all 0.1s ease;

      }

      .seasonal-ribbon__text-container {
        &__text {
          &::after {
            top: -10px;
            transform: translateX(-50%) rotate(0);
          }
        }
      }
      .seasonal-ribbon__text-container__text {
        width: 100%;
        text-align: center;
      }

      .seasonal-ribbon__countdown-container {
        .promo-counter__bg {
          padding: 10px;
          margin-right: 4px;
          padding-top: 3px;
        }

        .btn {
          margin-left: 5px;
        }
      }
    }
  }
}

.seasonal-ribbon-section ~ .main-header {
  @media (max-width: 1023px) {
    top: $ribbon-height !important;
  }
}

.seasonal-ribbon-section.is-expanded ~ .main-header {
    @media (max-width: 1023px) {
      top: $ribbon-height-expanded !important;
    }
}

@keyframes pulse-animation {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.5;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.1);
    opacity: 0;
  }
}
