/* stylelint-disable selector-max-type */
@import '~modern-normalize/modern-normalize.css';
@import '~aos/src/sass/aos.scss';
@import '~@splidejs/splide/dist/css/splide-core.min.css';
@import '~svg-spritemap-webpack-plugin/sprites';
@import '~tingle.js/dist/tingle.min.css';
@import 'fonts';
@import 'colors';
@import 'utils';
@import 'framework';
@import 'keyframes';
@import 'popup';
@import 'blocks/monthly-promo-ribbon';
@import 'components/social-proof';
@import 'blocks/seasonal-promo-ribbon-drop-down';


// variables

$mobile-menu-z-index: 200;
$page-column-gap: 20px;

// root styles

// max grid 1275 1355 with paddings
// mid grid 1074

:root {
  --num-grid-columns: 4;
  --page-max-width: 1240px;
  --page-gutter: 16px;
  --responsive-rem: 0.8vmin;
  --header-height: 64px;
  --ribbon-height: 0px;
  --sticky-height: calc(var(--header-height) + var(--ribbon-height));
  --basic-section-gap: 80px;
  --extended-section-gap: 50px;
}

@media screen and (min-width: 768px) {
  :root {
    --num-grid-columns: 8;
    --page-gutter: 70px;
    --basic-section-gap: 100px;
  }
}

@media screen and (min-width: 1024px) {
  :root {
    --num-grid-columns: 12;
    --responsive-rem: 0.4938vh;
    --extended-section-gap: 250px;
  }
}

a,
abbr,
acronym,
address,
applet,
big,
blockquote,
body,
button,
caption,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
iframe,
img,
input,
ins,
kbd,
label,
legend,
li,
object,
ol,
p,
pre,
q,
s,
samp,
small,
span,
strike,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
ul,
var {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  vertical-align: middle;
}

caption,
td,
th {
  text-align: left;
  font-weight: 400;
  vertical-align: middle;
}

ol,
ul {
  list-style: none;
}

a img {
  border: none;
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
}

img {
  height: auto;
}

.img-responsive {
  height: auto;
  width: 100%;
}

input[type='number'] {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

// lazyload

[data-lazy-background] {
  &,
  *,
  &::before,
  &::after {
    background-image: none !important;
  }
}

//

body {
  font-family: 'Poppins', 'Trebuchet MS', Verdana, sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  background: $black1-color;
  color: $primary-text-color;

  @media screen and (min-width: 1024px) {
    font-size: 14px;
    background: $black2-color;
  }

  /* stylelint-disable-next-line selector-no-qualifying-type */
  &.preload * {
    animation-duration: 0s !important;
    transition: background-color 0s, opacity 0s, color 0s, width 0s, height 0s,
      padding 0s, margin 0s !important;
  }
  /* stylelint-disable-next-line selector-no-qualifying-type */
  &.mob-menu-open {
    overflow: hidden;

    @media (min-width: 1024px) {
      overflow: auto;
    }
  }
}

a {
  color: $brand-color;
  transition: color 0.25s linear;

  &:hover {
    color: #fff;
  }
}

// hide recaptcha badge (disclamer displayed in the footer)

.grecaptcha-badge {
  opacity: 0;
  visibility: hidden;
}

// aos overflow fix

@media (min-width: 375px) {
  // html {
  //   overflow-x: hidden;
  // }

  #fullpage {
    // width: 100vw;
    overflow-x: hidden;
  }
}

// sections

.fp-bg {
  overflow: hidden;
  top: 0;
  bottom: 0;
  width: 100%;
  position: absolute;
  z-index: -1;
  background-size: cover;
  opacity: 1;
  transition: opacity 0.25s ease;

  &[data-lazy-background] {
    opacity: 0;
  }
}

.screen-section,
.basic-section,
.extended-section {
  position: relative;
}

.screen-section {
  height: calc(100vh - var(--sticky-height));
  min-height: 985px;
}

.basic-section {
  padding: var(--basic-section-gap) 0;
}

.extended-section {
  padding: var(--extended-section-gap) 0;
}

// make offset for sticky header when header exists

.main-header + #fullpage > .screen-section:first-child {
  height: 100vh;
  margin-top: calc(-1 * var(--sticky-height));
  padding-top: calc(var(--ribbon-height) + var(--sticky-height));

  @media (min-width: 1024px) {
    padding-top: 0;
    min-height: 970px;
    max-height: 130vw;
  }
}

// splidejs styles override

.splide__arrows {
  @keyframes rotate-cw {
    100% {
      transform: rotate(1turn);
    }
  }

  @keyframes rotate-ccw {
    100% {
      transform: rotate(-1turn);
    }
  }

  .splide__arrow {
    position: relative;
    display: block;
    width: 42px;
    height: 42px;
    cursor: pointer;
    transition: transform 0.15s linear;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 42px;
      height: 42px;
      background-image: url('/img/slider_arrow_circle.svg');
      background-size: cover;
      background-repeat: no-repeat;
      animation-iteration-count: 1;
    }

    &:hover {
      transform: scale(1.2);
    }

    &.spinning {
      &::before {
        animation: rotate-ccw 0.25s cubic-bezier(0.46, 0.03, 0.52, 0.96);

        @media (min-width: 768px) {
          animation-duration: 0.375s;
        }
      }
    }

    &--prev {
      svg {
        transform: translateX(-2px) translateY(-1px);
      }
    }

    &--next {
      transform: rotate(180deg);

      &:hover {
        transform: scale(1.2) rotate(180deg);
      }

      &.spinning {
        &::before {
          animation-name: rotate-cw;
        }
      }

      svg {
        transform: translateX(-2px) translateY(0px);
      }
    }

    svg {
      fill: $brand-color;
    }
  }
}

// page skeleton

.main-header {
  width: 100%;
  position: sticky;
  height: var(--header-height);
  top: 0;
  z-index: $mobile-menu-z-index;

  @include dark-bg-transparent(true);

  &.opaque {
    @include dark-bg(true);
  }

  .grid-row {
    @media (min-width: 1024px) and (max-width: 1049px) {
      --page-gutter: 32px;
    }
  }
}

.countdown-ribbon-section ~ .main-header {
  top: 70px;
  transition: 0.3s;
  box-shadow: none;
  @media (min-width: 778px) {
    top: 50px;
  }

  &.mob-menu-open {
    top: 0;
    position: fixed;
  }
}

.language-switcher-select {
  appearance: none; /* Remove default styling */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  padding: 9px 24px 9px 9px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #000;
  background-image: url('data:image/svg+xml;utf8,<svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L8 8L15 1" stroke="%23ffffff" stroke-width="2"/></svg>'); /* Custom white arrow */
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  margin-top: 4px;
  transition: background-image .25s ease-in-out;

  @media (min-width: 1024px) {
    margin-left: 8px;
  }

  &:focus {
    outline: none;
    border-color: $brand-color;
  }
}

.language-switcher-select-mobile {
  margin-top: 32px;
  font-size: 16px;
  margin-left: 0;
}

.fvps-container {
  width: 100%;
  padding: 0 var(--page-gutter);
  margin-right: auto;
  margin-left: auto;
  max-width: var(--page-max-width);

  &.full-mobile {
    padding: 0;

    @media (min-width: 1024px) {
      padding: 0 var(--page-gutter);
    }
  }
}

.grid-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  &.centered {
    height: 100%;
    justify-content: center;
  }
}

.grid-row {
  width: 100%;
  max-width: var(--page-max-width);
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(var(--num-grid-columns), 1fr);
  gap: 0 $page-column-gap;
  padding: 0 var(--page-gutter);

  &.no-padding {
    padding: 0;
  }
}

.full-width-wrap {
  grid-column: span var(--num-grid-columns);
}

// header and nav

.mobile-nav-wrap {
  .logo,
  .mobile-menu-button {
    position: relative;
    z-index: $mobile-menu-z-index + 1;
  }
}

.desktop-nav-wrap {
  display: none;
}

@media screen and (min-width: 1024px) {
  .mobile-nav-wrap {
    display: none;
  }

  .desktop-nav-wrap {
    display: flex;
  }
}

.nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  height: var(--header-height);

  ul {
    li {
      list-style: none;
    }
  }
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgb(#000, 0.5);
  padding-top: calc(64px + 70px);
  height: 100vh;
  z-index: $mobile-menu-z-index;
  visibility: hidden;
  opacity: 0;
  transition: all 0.375s;
  overflow-x: hidden; // removes small horizontal scroll from &::before {right: -15px}

  &::before {
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: -15px;

    @include sprite('mobile-menu-bg');
    background: {
      size: cover;
      color: $black1-color;
      position: top right;
    }
    transform-origin: 0 0;
    transform: skew(-14deg) translateX(-120%);
    transition: all 0.275s 0.1s;
    z-index: -1;
  }

  &__list,
  &__item {
    list-style: none;
    padding: 0;
  }

  &__list {
    padding: 0 var(--page-gutter);
    //margin-top: 100px;
  }

  &__item {
    margin: 32px 0 0;
    opacity: 0;
    transform: translateY(-10px);
    transition: transform 0.5s ease, opacity 0.6s ease;

    &:first-child {
      margin-top: 0;
    }
  }

  &__link {
    @include menu-dash(false);

    display: inline-block;
    color: $primary-text-color;
    font-size: 24px;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    flex: 1 0 95%;

    &.active {
      color: $brand-color;
    }

    &.mobile-dropdown-heading:hover {
      color: #fff;
    }
  }
}

.mobile-menu-button.open + .mobile-menu {
  visibility: visible;
  opacity: 1;

  &::before {
    transform: skew(0) translateX(0);
  }

  .mobile-menu__item {
    opacity: 1;
    transform: translateY(0);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    &:last-child {
      margin: 32px 0 0;
    }

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        transition-delay: #{0.07 * $i + 0.2}s;
      }
    }

    .mobile-menu-expansion-icon {
      flex: 0 0 5%;
      transition: 0.3s;

      &.show {
        transform: rotate(180deg);
      }
    }

    .mobile-menu-expandable {
      flex: 1 1 100%;
      max-height: 0;
      overflow: hidden;
      transition: 0.3s;

      &.show {
        padding: 16px 16px 0;
        max-height: 500px;
      }

      .mobile-submenu {
        &__item {
          margin-bottom: 4px;
        }

        &-link {
          padding: 16px 0;
          font-weight: 600;
          font-size: 16px;
          text-decoration: none;
          display: flex;
          align-items: center;
          color: #fff;

          &.active {
            color: $brand-color;
          }

          .submenu-icon {
            margin-right: 12px;
          }
        }
      }
    }
  }
}

.desktop-menu {
  display: flex;
  text-align: center;
  font-weight: 600;

  &__item {
    @include menu-dash();

    flex-shrink: 0;
    margin-left: 0;
    /*margin-left: 1vmax;*/
    padding: 10px;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    transition: color 0.25s ease;

    &:hover,
    &.active {
      color: $brand-color;
    }
  }

  &__heading {
    @include menu-dash();
    cursor: pointer;
  }

  &__title {
    flex-shrink: 0;
    margin-left: 0;
    /*margin-left: 1vmax;*/
    padding: 10px;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    transition: color 0.25s ease;
    position: relative;

    &:hover,
    &.active {
      color: $brand-color;
    }
  }

  li {
    list-style: none;
  }
}

.desktop-submenu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  color: #fff;
  background: $black1-color;
  font-size: 14px;
  text-align: left;
  padding: 24px;
  width: max-content;
  max-width: 250px;
  display: none;

  &.show {
    display: flex;
  }

  &-list {
    &__item {
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-link {
    text-decoration: none;
    color: #fff;
    padding: 16px 0;
    display: flex;
    align-items: center;
    text-transform: none;

    &.active {
      color: $brand-color;
    }

    .submenu-icon {
      margin-right: 12px;
    }
  }
}

.mobile-menu-button {
  width: 28px;
  height: 20px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;

  span {
    width: 28px;
    display: block;
    position: absolute;
    height: 1px;
    width: 100%;
    background: #fff;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  span:nth-child(1) {
    top: 0;
  }

  span:nth-child(2) {
    top: 8px;
  }

  span:nth-child(3) {
    width: 20px;
    top: 16px;
    transform: translateX(8px);
  }

  &.open span:nth-child(1) {
    top: 8px;
    transform: rotate(135deg);
  }

  &.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }

  &.open span:nth-child(3) {
    width: 28px;
    top: 8px;
    transform: rotate(-135deg);
  }
}

// standard hero sections

.hero-section {
  .centered {
    justify-content: initial;

    @media (min-width: 1024px) {
      justify-content: center;
    }
  }
}

.hero-content {
  padding-top: responsive-rem(20);

  @media (min-width: 1024px) {
    padding-top: 0;
  }

  &__title {
    @include responsive-grid-column(0, 1 / span 7, 1 / span 5);

    @media (min-width: 1025px) {
      font-size: 56px;
    }
  }

  &__text {
    @include responsive-grid-column(0, 0, 1 / span 5);
    margin-top: 16px;
  }

  &__list {
    @include responsive-grid-column(0, 1 / span 9);
    margin-top: 0;
  }

  &__cta {
    @include responsive-grid-column(0, 1 / span 5, 1 / span 3);
    @include hero-cta;
  }
}

// standard jumbotron section

.jumbotron {
  align-items: center;

  &__content {
    @include responsive-grid-column(0, span 6);

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media (min-width: 1024px) {
      align-items: initial;
      text-align: left;
    }
  }

  &__banner {
    @include responsive-grid-column(0, 7 / span 6);

    margin-top: responsive-rem(20);
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @media (min-width: 1024px) {
      margin-top: 0;
    }

    img {
      margin: 0 auto;
      width: 100%;
      max-width: 500px;

      @media (min-width: 1024px) {
        max-width: unset;
      }
    }
  }

  &__title {
    @media (min-width: 768px) {
      font-size: 48px;
    }
  }

  &__text {
    margin-top: 16px;
  }

  &__cta {
    @include hero-cta;
    max-width: 250px;

    @media (min-width: 1024px) {
      max-width: 350px;
    }
  }
}

// cookie popup

.cookie-consent {
  visibility: hidden;
  opacity: 0;
  padding: 16px;
  position: fixed;
  width: 385px;
  left: 15px;
  bottom: 15px;
  z-index: 9999;
  background: $black3-color;
  color: #fff;
  border-radius: 0;
  letter-spacing: 0.02em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  user-select: none;
  border: 1px solid #323232;
  &.show {
    display: flex;
    visibility: visible;
    opacity: 1;
    z-index: 999;
  }

  &__content {
    padding: 24px;

    &__title {
      margin-top: 12px;
      font-weight: 500;
    }

    &__text {
      margin-top: 8px;
      font-size: 12px;
      margin-bottom: 0;
    }
  }

  &__buttons {
    padding: 24px 0;
    border-top: 1px solid #e5e5e5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;

    .btn {
      flex: 1 1 calc(50% - 16px);
    }
    .btn-deny {
      background: transparent;
      color: #fff;
      border: 1px solid #fff;

    }
  }
}
