@use 'sass:math';

@mixin sprite-mask($name) {
  $sprite: map-get($sprites, $name);

  mask-image: url($sprite);
}

@mixin menu-dash($has-hover-line: true) {
  &:hover {
    color: $brand-color;

    @if $has-hover-line {
      &::after {
        width: 100%;
        background-color: $brand-color;
      }
    }
  }

  &:focus {
    color: $brand-color;

    @if $has-hover-line {
      &::after {
        width: 100%;
        background-color: $brand-color;
      }
    }
  }

  &::after {
    content: '';
    display: block;
    margin-top: 4px;
    width: 28px;
    height: 2px;
    background: #fff;
    transition-property: background-color, width;
    transition-duration: 0.25s;
    transition-timing-function: ease-in-out;
  }
}

@mixin dark-bg($add-box-shadow: false) {
  background: $black1-color;

  @if $add-box-shadow {
    box-shadow: 0 0 15px $black1-color;
  }

  @media (min-width: 1024px) {
    background: $black2-color;

    @if $add-box-shadow {
      box-shadow: 0 0 15px $black2-color;
    }
  }
}

@mixin dark-bg-transparent($add-box-shadow: false) {
  background-color: rgb($black1-color, 0.92);

  @if $add-box-shadow {
    box-shadow: 0 0 15px $black1-color;
  }

  @media (min-width: 1024px) {
    background-color: rgb($black2-color, 0.92);

    @if $add-box-shadow {
      box-shadow: 0 0 15px $black2-color;
    }
  }
}

@mixin hover-underline {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

@mixin button-click {
  transform: translateY(1px);

  &::after {
    box-shadow: 0 0 0 0 rgb($brand-color, 0.33);
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
    transition: 0s;
  }
}

@mixin button-hover-color {
  background-color: $brand-color;
  color: darken($brand-color, 50%);
}

@mixin button-default-color {
  background: $brand-xray-color;
  color: $brand-color;
}

@mixin dropdown-arrow {
  position: relative;

  &::after {
    content: '';
    margin-top: -6px;
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    right: 16px;
    border-bottom: 2px solid $brand-color;
    border-right: 2px solid $brand-color;
    pointer-events: none;
    transform-origin: 66% 66%;
    transform: rotate(45deg);
    transition: all 0.15s ease-in-out;
  }
}

@mixin responsive-grid-column(
  $xl-value: 0,
  $lg-value: 0,
  $md-value: 0,
  $xs-value: 0
) {
  @if $xs-value != 0 {
    grid-column: $xs-value;
  } @else {
    grid-column: span var(--num-grid-columns);
  }

  @if $md-value != 0 {
    @media screen and (min-width: 768px) {
      grid-column: $md-value;
    }
  }

  @if $lg-value != 0 {
    @media screen and (min-width: 1024px) {
      grid-column: $lg-value;
    }
  }

  @if $xl-value != 0 {
    @media screen and (min-width: 1280px) {
      grid-column: $xl-value;
    }
  }
}

@function color-without-sharp($color) {
  $hex: str-slice(ie-hex-str($color), 4);

  @return unquote('#{$hex}');
}

@mixin svg-arrow($color: $brand-color) {
  $string: color-without-sharp($color);

  background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 10'%3E%3Cpath d='m2 2 5.5 6L13 2' stroke='%23#{$string}' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}

@mixin svg-pointer($color: $brand-color) {
  $string: color-without-sharp($color);

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16' viewBox='0 0 10 16'%3E%3Cpath fill='%23#{$string}' d='M8.928 10.284H5.794l1.65 4.25a.612.612 0 01.003.435.568.568 0 01-.283.315l-1.452.67c-.27.125-.575-.018-.69-.304l-1.567-4.036L.895 14.4c-.342.371-.895.085-.895-.401V.57C0 .058.589-.192.895.169L9.298 9.31c.339.35.089.973-.37.973z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
}

@mixin svg-success($color: $brand-color) {
  $string: color-without-sharp($color);

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' fill='none' viewBox='0 0 10 7'%3E%3Cpath stroke='%23#{$string}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M1 3l3 3 5-5'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
}

@mixin svg-checkbox($color: $brand-color) {
  $string: color-without-sharp($color);

  background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Cpath d='m1 5 3 3 5-5' stroke='%23#{$string}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}

@mixin svg-check-simple($color: $brand-color,$stroke-width: 2) {
  $string: color-without-sharp($color);

  background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.03589L4 6.03589L9 1.03589' stroke='%23#{$string}' stroke-width='{$stroke-width}' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}

@mixin bg-filter($bg, $pseudo-element: 'after') {
  &::#{$pseudo-element} {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 100%;
    height: 100%;
    background: $bg;
  }
}

@mixin ultrawide-bg-fix($add-filter: true) {
  // max-width: 1920px;
  max-width: 1440px;
  left: 50%;
  transform: translateX(-50%);

  @if $add-filter {
    @media (min-width: 1441px) {
      @include bg-filter(
        linear-gradient(90deg, rgba(2, 6, 7, 0) 70%, #020607 100%),
        'before'
      );
    }
  }
}

@mixin play-button($with-caption: false) {
  @if $with-caption {
    padding: 10px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  &::after {
    content: '';

    @include sprite('placeholder-play');

    @if $with-caption {
      margin-bottom: 10px;
    } @else {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    width: 65px;
    height: 65px;
    backdrop-filter: blur(3px);
    border: 2px solid #fff;
    border-radius: 50%;
    transform-origin: center;
    transition: all 0.25s ease;
  }

  &:hover {
    &::after {
      @if $with-caption {
        transform: scale(1.1);
      } @else {
        transform: translate(-50%, -50%) scale(1.2);
      }
      opacity: 1;
    }
  }
}

.vimeo-player {
  position: relative;
  width: 100%;

  &::before {
    content: '';
    display: block;
    padding-top: 56.25%;
  }

  &__inner {
    position: absolute;
    inset: 0 0 0 0;
    cursor: pointer;

    &[data-placeholder] {
      @include play-button;
    }

    iframe,
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 1px);
    }

    img {
      object-fit: contain;
      max-width: unset;
    }
  }
}

@function responsive-rem($count) {
  @return calc($count * var(--responsive-rem));
}

@function header-offset($offset: 0px) {
  @return calc($offset + var(--header-height));
}

.text-branded {
  color: $brand-color;
}

.text-faded {
  color: $faded-text-color;
}

.text-error {
  color: $form-error-color;
}

.visible-strong {
  font-weight: 700;
}

.overflow-hidden {
  overflow: hidden;
}

.simple-heading {
  font-weight: 700;

  font-size: 30px;
  font-size: responsive-rem(9);

  line-height: 1.07;
  line-height: responsive-rem(11);

  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: 42px;
    line-height: 1.07;
  }
  /* Styles applied if .section-heading next element is section heading text */
  /* Styles applied if .section-heading next element is a button */
  &:has(+ .simple-subheading),
  &:has(+ .section-heading-text),
  &:has(+ .btn) {
    margin-bottom: 24px;
    @media (min-width: 1024px) {
      margin-bottom: 30px;
    }
  }

  /* Styles applied only if .section-heading has no siblings */
  &:not(:has(+ *)),
  &:has(+ div):not(:has(+ :not(div))) {
    margin-bottom: 24px;
    @media (min-width: 1024px) {
      margin-bottom: 60px;
    }
  }
}

.simple-subheading {
  font-weight: 600;

  font-size: 24px;
  @media (min-width: 768px) {
    font-size: 28px;
  }
}

.section-heading-text {
  font-weight: 500;
  font-size: 14px;
  /* Styles applied only if .section-heading has no siblings */
  &:not(:has(+ *)),
  &:has(+ div):not(:has(+ :not(div))) {
    margin-bottom: 24px !important;
    @media (min-width: 1024px) {
      margin-bottom: 60px !important;
    }
    &.small-section {
      margin-bottom: 0 !important;
      @media (min-width: 1024px) {
        margin-bottom: 0 !important;
      }
    }
  }
}

.text-max-w-30 {
  max-width: 30%;
  margin: 0 auto;
  text-align: center;
}
.text-max-w-40 {
  max-width: 40%;
  margin: 0 auto;
  text-align: center;
}
.text-max-w-50 {
  max-width: 50%;
  margin: 0 auto;
  text-align: center;
}
.text-max-w-60 {
  max-width: 60%;
  margin: 0 auto;
  text-align: center;
}
.text-max-w-70 {
  max-width: 70%;
  margin: 0 auto;
  text-align: center;
}
.text-max-w-80 {
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
}
.text-max-w-90 {
  max-width: 90%;
  margin: 0 auto;
  text-align: center;
}
.text-max-w-100 {
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
}

@media (min-width: 768px) {
  .text-max-w-30-md {
    max-width: 30%;
    margin: 0 auto;
    text-align: center;
  }
  .text-max-w-40-md {
    max-width: 40%;
    margin: 0 auto;
    text-align: center;
  }
  .text-max-w-50-md {
    max-width: 50%;
    margin: 0 auto;
    text-align: center;
  }
  .text-max-w-60-md {
    max-width: 60%;
    margin: 0 auto;
    text-align: center;
  }
  .text-max-w-70-md {
    max-width: 70%;
    margin: 0 auto;
    text-align: center;
  }
  .text-max-w-80-md {
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
  }
  .text-max-w-90-md {
    max-width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  .text-max-w-100-md {
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
  }
}

@media (min-width: 1024px) {
  .text-max-w-30-lg {
    max-width: 30%;
    margin: 0 auto;
    text-align: center;
  }
  .text-max-w-40-lg {
    max-width: 40%;
    margin: 0 auto;
    text-align: center;
  }
  .text-max-w-50-lg {
    max-width: 50%;
    margin: 0 auto;
    text-align: center;
  }
  .text-max-w-60-lg {
    max-width: 60%;
    margin: 0 auto;
    text-align: center;
  }
  .text-max-w-70-lg {
    max-width: 70%;
    margin: 0 auto;
    text-align: center;
  }
  .text-max-w-80-lg {
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
  }
  .text-max-w-90-lg {
    max-width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  .text-max-w-100-lg {
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
  }
}

.visuallyhidden {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
}

.max-w-img {
  max-width: 100%;
}

// Image lightbox
.lightbox-img {
  cursor: pointer;
}

.lightbox {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  cursor: pointer;

  &__backdrop {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
  }

  &__full-image {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

// .t1 {
//   font-size: responsive-rem(6);
//   // line-height: responsive-rem(8);

//   @media (min-width: 768px) {
//     font-size: 24px;
//     line-height: 1.5;
//   }
// }

// .t2 {
//   font-size: responsive-rem(5.3);
//   line-height: responsive-rem(7.3);

//   @media (min-width: 768px) {
//   }
// }

// .t3 {
//   font-size: responsive-rem(3.6);
//   line-height: responsive-rem(6.67);

//   @media (min-width: 768px) {
//   }
// }

.simple-list {
  $this: &;

  padding-top: 16px;
  padding-left: 20px;
  list-style: disc;
  // margin: -16px 0 0;
  // display: flex;
  // flex-wrap: wrap;
  // flex-direction: column;

  &__item {
    $bullet-width: 6px;
    margin-right: 32px;
    break-inside: avoid-column;

    &::marker {
      color: $brand-color;
      font-size: 16px;
      line-height: 1;
    }

    &:not(:first-child) {
      margin-top: 8px;

      @media (min-width: 768px) {
        margin-top: 16px;
      }
    }
  }

  &.checkmarks {
    padding-left: 0;

    #{$this}__item {
      padding-left: 24px;
      position: relative;
      list-style: none;

      &::before {
        content: '';
        display: block;
        width: 10px;
        height: 7px;
        position: absolute;
        left: 0;
        top: 6px;

        @include svg-success;
      }
    }
  }

  &.arrows {
    #{$this}__item {
      position: relative;
      list-style: none;

      &::before {
        content: '';
        display: block;
        width: 13px;
        height: 13px;
        position: absolute;
        left: -20px;
        top: 4px;

        @include sprite('rightarrow');
      }
    }
  }
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.section-heading-text-max-width {
  // sets max width to approx 70-80 chars - for <p> element section headings
  // Only for desktop, mobile we want full width and aligned left
  @media (min-width: 1024px) {
    max-width: 685px;
    margin: 0 auto;
    text-align: center;
  }
}

.btn-disclaimer-text {
  color: #C5C5C5 !important;
}

.btn {
  position: relative;
  display: inline-block;
  padding: 16px 42px;

  background: $brand-color;
  border: 1px solid $brand-color;
  color: $black1-color;
  border-radius: 8px;
  user-select: none;
  text-decoration: none;
  text-align: center;
  font-weight: 600;
  line-height: 1.15;
  white-space: nowrap;
  transition-property: background-color, color, box-shadow;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
  width: 100%;
  @media (min-width: 415px) {
    width: auto;
  }
  @media (min-width: 1024px) {
    padding: 16px 42px;
  }


  &::after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 8px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
    box-shadow: 0 0 10px 40px rgb($brand-color, 0.33);
  }

  &:active {
    @include button-click;
  }

  &:disabled {
    opacity: 0.8;
    color: $faded-text-color;
    border-color: $faded-text-color;
  }

  &:not(:disabled):hover {
    transition-duration: 0.1s;

    @include button-hover-color;
    cursor: pointer;
  }

  &:focus {
    box-shadow: 0 0 15px -2px $brand-color;
  }

  &.loading::before {
    content: '';
    position: relative;
    top: 4px;
    margin-top: -8px;
    margin-right: 12px;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid transparent;
    border-top-color: $brand-color;
    border-right-color: lighten($brand-color, 5);
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
  }

  &.btn-secondary {
    @include button-default-color;
    border-color: $brand-color;
    font-weight: 600;
    transition-duration: 0.3s;
    transition-timing-function: ease;

    &:hover {
      box-shadow: 0 0 20px rgba(77, 228, 80, 0.5);
      //transform: scale(1.05);
    }

    &:focus {
      box-shadow: 0 0 11px 2px rgba(77, 228, 80, 0.55);
    }
    &.btn-mask-icon {
      color: #fff;
      &::after {
        color: #fff;
        background-color: #fff;
      }
    }
  }

  &.centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &.mw-200 {
    max-width: 200px;
    min-width: fit-content;
    width: 100%;
  }

  &.btn-mask-icon {
    color: $black1-color;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding-top: 14px;
    padding-bottom: 14px;

    &:not(:disabled):hover {
      background: #3CBB3F;
      box-shadow: 0px 4px 4.4px 0px rgba(199, 199, 199, 0.25);
      &::after {
        left: 8px;
      }
    }
    &::after {
      content: '';
      mask-repeat: no-repeat;
      mask-position: center;
      flex-shrink: 0;
      width: 14px;
      height: 14px;
      color: $black1-color;
      background-color: $black1-color;
      position: relative;
      border-radius: 0;
      opacity: 1;
    }

    .btn-caption {
      flex-basis: initial;
      padding-right: 10px;
    }
  }

  &.btn-with-arrow {
    text-decoration: none;

    &::after {
      -webkit-mask-size: 9px;
      mask-size: 9px;
      @include sprite-mask('chevron-right');
    }
  }
}

.inline-link {
  text-decoration: none;
  font-weight: 600;
  color: $brand-color;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding-top: 14px;
  padding-bottom: 14px;
  &::after {
    -webkit-mask-size: 9px;
    mask-size: 16px;
    content: '';
    mask-repeat: no-repeat;
    mask-position: center;
    flex-shrink: 0;
    width: 21px;
    height: 16px;
    color: $brand-color;
    background-color: $brand-color;
    position: relative;
    border-radius: 0;
    opacity: 1;
    @include sprite-mask('arrow-right');
  }
}

@mixin hero-cta {
  margin-top: 24px;
  width: 50%;
  min-width: 250px;

  @media (min-width: 768px) {
    margin-top: 32px;
    width: 85%;
  }

  .btn {
    min-width: 300px;
    font-size: 12px;

    &:not(:first-child) {
      margin-top: 16px;
    }

    @media (min-width: 768px) {
      font-size: inherit;
    }
  }
}

.animated-window {
  // min-height: 300px;
  position: relative;
  overflow: hidden;
  border: 1px solid $brand-color;
  border-radius: 8px;

  &::before {
    content: '● ● ●';
    position: absolute;
    top: 12px;
    left: 12px;
    color: $brand-color;
    font-size: 22px;
    font-weight: 400;
    line-height: 11px;
  }

  .tmp {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-60%, -40%) rotateZ(-15deg);
    font-size: 32px;
    width: 100%;
    text-align: center;
    white-space: nowrap;
  }
}

@mixin logo-slider($var-name, $animation-name, $height) {
  .logo-slider {
    @keyframes running-line-#{$animation-name} {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(calc(-1 * var(#{$var-name})));
      }
    }

    grid-column: span 12;
    width: 100%;
    height: $height;
    overflow: hidden;
    position: relative;

    &__track {
      // track width is calculated by Smarty and being put in style attribute
      animation: running-line-#{$animation-name} 28s linear infinite;
      display: flex;
      align-items: center;
    }

    &__slide {
      margin: 0 20px;
      text-align: center;

      img {
        display: block;
      }
    }
  }
}

@mixin logo-slider-vertical($var-name, $animation-name) {
    @keyframes running-line-#{$animation-name} {
      0% {
        transform: translateY(0);
      }

      100% {
        transform: translateY(calc(-1 * var(#{$var-name})));
      }
    }

    width: 100%;
    position: relative;

    // track width is calculated by Smarty and being put in style attribute
    animation: running-line-#{$animation-name} 10s linear infinite;
}

@mixin broker-ribbon {
  .bottom-ribbon {
    position: absolute;
    bottom: 24px;

    @include logo-slider('--brokers-track-width', 'brokers', 37px);

    @media (min-width: 1024px) {
      bottom: 64px;
    }

    .logo-slider {
      opacity: 0.6;

      @media (min-width: 1024px) {
        opacity: 0.33;
      }

      &__slide {
        transform: scale(0.8);

        @media (min-width: 1024px) {
          transform: scale(1);
        }
      }
    }
  }
}

@mixin trustpilot-widget-mini {
  .trustpilot-widget iframe {
      margin-top: -15px;
      margin-left: -50px;
      transform: scale(.6);

      @media (min-width: 767px) {
        transform: scale(.7);
        margin-left: -40px;
      }
    }
}

@mixin tech-ribbon {
  .bottom-ribbon {
    position: absolute;
    bottom: 24px;

    @include logo-slider('--tech-track-width', 'techs', 42px);

    @media (min-width: 1024px) {
      bottom: 64px;
    }

    .logo-slider {
      opacity: 0.6;

      @media (min-width: 1024px) {
        opacity: 0.33;
      }

      &__slide {
        transform: scale(0.8);

        @media (min-width: 1024px) {
          transform: scale(1);
        }
      }
    }
  }
}

// grid area letters

$_letters-list: a b c d e f g h i j k l m; // add more if needed

@each $letter in $_letters-list {
  .grid-area-#{$letter} {
    grid-area: $letter;
  }
}

// beam animation

@keyframes beam-fly {
  0% {
    transform: translateX(-100%);
    width: 0;
  }

  10% {
    transform: translateX(0%);
    width: 100%;
  }

  20%,
  100% {
    transform: translateX(100%);
  }
}

@mixin beam-fly($speed, $delay, $wait: false) {
  $freezetime: 0;

  @if $wait {
    $freezetime: math.div($speed, 10);
  }

  &::after {
    animation: beam-fly $speed #{$delay + $freezetime} ease-out infinite;
  }
}

.fp-bg {
  .beam {
    width: 100px;
    height: 2px;
    position: absolute;
    transform-origin: top right;
    overflow: hidden;
    filter: drop-shadow(0 0 6px #56d858);

    &::after {
      // animation: none !important;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        270deg,
        rgba(77, 227, 80, 0.992204) 0%,
        rgba(77, 227, 80, 0.3125) 62.59%,
        rgba(77, 227, 80, 0) 102.71%
      );
      filter: drop-shadow(0 0 6px #56d858);
      border-radius: 5px;
      transform: translateX(-100%);
    }
  }
}

// Dot blink animation

@keyframes dot-blink {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  30% {
    opacity: 1;
    transform: scale(0.3);
  }

  50% {
    opacity: 0;
    transform: scale(0);
  }

  75% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0);
  }
}

@mixin dot-blink($speed, $delay, $wait: false) {
  $freezetime: 0;

  @if $wait {
    $freezetime: math.div($speed, 10);
  }

  &::after {
    animation: dot-blink $speed #{$delay + $freezetime} ease-out infinite;
  }
}

.fp-bg {
  .dot {
    width: 5px;
    height: 5px;
    position: absolute;
    transform-origin: top right;
    overflow: hidden;
    filter: drop-shadow(0 0 6px #56d858);

    &::after {
      // animation: none !important;
      transform: scale(1);
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: $brand-color;
      filter: drop-shadow(0 0 6px #56d858);
      border-radius: 5px;
      // transform: translateX(-100%);
    }
  }
}

// Server lights animation

@keyframes status-blink {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 1;
  }

  10% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  30% {
    opacity: 0;
  }

  32% {
    opacity: 1;
  }

  35% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@mixin status-blink($speed, $delay, $wait: false) {
  $freezetime: 0;

  @if $wait {
    $freezetime: math.div($speed, 10);
  }

  animation: status-blink $speed #{$delay + $freezetime} ease-out infinite;
}

.fp-bg {
  .status-light {
    opacity: 0;
  }
}

.text-xs {
  font-size: 12px;
}

//Mixing for blinking animation used on server animation and algo trader
// Define a map to store random values for each letter
$status-values: ();
$letters: a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r;

// Loop through each letter and assign random values
@each $letter in $letters {
  $status-values: map-merge($status-values, ($letter: (speed: random(3) + 0.3s, delay: random(3000) + 0ms)));
}

@mixin status-blink-animation {
  opacity: 0;

  @each $letter, $values in $status-values {
    $speed: map-get($values, speed);
    $delay: map-get($values, delay);

    &.#{$letter} {
      opacity: 1;
      @include status-blink($speed, $delay);
    }
  }
}



.tree-promise {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  &__text {
    font-size: 12px;
    color: #fff;
    display: block;
    gap: 2px;

    @media (min-width: 576px) {
      display: flex;
    }
  }
  &__tree-icon {
    object-fit: contain;
    width: 40px;
    margin-right: 5px;
  }

  &__ecologi-logo {
    color: #fff;
  }

  svg {
    margin: 0 0 -7px 2px;
    @media (min-width: 576px) {
      margin: 0 0 -11px 2px;
    }
  }
}

.volume-traded {
  color: #fff;
  display: block;
  text-decoration: none;
  cursor: pointer;
}



@keyframes randomBlink {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  48% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  63% {
    opacity: 0;
  }
  76% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@mixin random-blink-animation($delay, $duration) {
  animation: randomBlink #{$duration}s infinite;
  animation-delay: #{$delay}s;
}

.google-rating {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-reviews {
    font-size: 11px;
    font-weight: 400;
  }
}


.tooltip-container {
  position: relative;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;

  .information-tooltip-text {
    display: none;
    z-index: 2;
    background: #3f3f41;
    position: absolute;
    top: 35px;
    left: 50%;
    transform: translateX(-80%);
    padding: 10px;
    font-size: 12px;
    border-radius: 10px;
    width: 200px;

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #3f3f41;
      position: absolute;
      top: -10px;
      left: 80%;
      transform: translateX(-50%);
    }
  }

  .information-tooltip-icon {
    cursor: pointer;

    &:hover + .information-tooltip-text {
      display: block;
    }

    & > * {
      pointer-events: none;
    }
  }
}
