.social-proof {
  $this: &;

  display: none;
  position: fixed;
  z-index: 99;
  top: 80px;
  right: 10px;
  bottom: unset;
  left: 10px;
  box-shadow: 0px 0px 18px rgba(77, 227, 80, 0.25);
  border-radius: 8px;

  transition: 0.4s ease-in-out;
  transform: translateY(-500px);
  user-select: none;

  // put back 426 for mobile
  @media (min-width: 1024px) {
    display: block;
    min-width: 350px;
    max-width: 400px;
    top: unset;
    right: unset;
    bottom: 20px;
    left: 20px;
    transform: translateY(500px);
  }

  &.visible {
    transform: translateY(0);
  }

  &:hover {
    #{$this}__close {
      opacity: 1;
    }
  }

  &__close {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 26px;
    height: 26px;
    background: $black3-color;
    color: $brand-color;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    border: 1px solid rgb($brand-color, 0.44);
    border-radius: 50%;
    box-shadow: 0px 0px 18px rgba(77, 227, 80, 0.25);
    transition: opacity 0.2s;
    cursor: pointer;
    opacity: 0;

    @media (max-width: 425px) {
      opacity: 1;
    }
  }

  &__inner {
    overflow: hidden;
    background: $black3-color;
    border-radius: 10px;
  }

  &__content {
    display: flex;
    // padding: 10px;
  }

  &__icon {
    margin-left: 16px;
    flex-shrink: 0;
    width: 30px;
    filter: drop-shadow(0px 4px 24px rgba(8, 190, 11, 0.7));

    @media (min-width: 426px) {
      width: 50px;
    }
  }

  &__title {
    padding: 16px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__name,
  &__country {
    text-transform: capitalize;
    font-weight: 600;
  }

  &__name {
    display: inline-block;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: top;
  }

  &__action {
    font-size: 14px;
    line-height: 1.8;
    // opacity: 0.82;

    @media (min-width: 426px) {
      display: inline-block;
    }
  }

  &__product {
    font-weight: 500;
  }

  &__time {
    margin-top: 6px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #868686;
  }

  &[data-product='Basic'],
  &[data-product='Core'],{
    #{$this}__icon {
      @include sprite('vps-basic');
      background-size: contain;
    }
  }

  &[data-product='Basic+'],
  &[data-product='Edge'] {
    #{$this}__icon {
      @include sprite('vps-basicplus');
      background-size: contain;
    }
  }

  &[data-product='Standard'],
  &[data-product='Prime'] {
    #{$this}__icon {
      @include sprite('vps-standard');
      background-size: contain;
    }
  }

  &[data-product='High Freq'] {
    #{$this}__icon {
      @include sprite('vps-highfreq');
      background-size: contain;
    }
  }
}
