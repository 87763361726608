body {
  &.modal-open {
    overflow: hidden;
  }
}

.tingle-modal {
  cursor: default;
}

.tingle-modal-box {
  max-width: 850px;
}

.tingle-modal-box__content {
  padding: 0;
}

.xmas-popup {
  display: block;
  box-shadow: (0px 0px 100px rgb(77 227 80 / 25%));
  background-image: url('/img/xmas-tree.jpg');
  background-size: cover;
  background-position: left center;
  padding: 10%;

  .xmas-header {
    font-size: 36px;
    line-height: 107.5%;
    font-weight: bold;
    text-transform: uppercase;
    max-width: 350px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    .xmas-header,
    .xmas-description {
      text-align: center;
    }
  }

  .xmas-description {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    max-width: 400px;

    strong {
      font-weight: bold;
    }
  }

  a {
    min-width: 230px;
  }

  .btn {
    max-width: 230px;
    width: 100%;
  }

  .js-close-modal {
    position: absolute;
    top: 0.7rem;
    right: 0.7rem;
    cursor: pointer;
  }
}

/* Exit pop-up */
#exit-modal-main.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.7);
    transition: opacity 250ms;
    opacity: 0;
    z-index: -1;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
#exit-modal-main.overlay_active {
    transition: opacity 250ms;
    opacity: 1;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.free-vps-modal {

  &__modal-dialog {
    width: calc(100% - 30px);
    max-width: 414px;
    position: relative;
    z-index: 999;

    @media (min-width: 1024px) {
      max-width: 990px;
    }
  }

  &__modal-content {
      background-color: $black1-color;
      background-repeat: no-repeat;
      background-position: center;
      border: 1px solid $brand-color;
      max-height: 99vh;

      @media (min-width: 1024px) {
        height: 380px;
        //background-image: url("/img/free-vps-request/exit-pop-up-desktop@2x.jpg");
        background-size: cover;
      }
  }

  &__modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;

    @media (max-width: 1023px) {
      position: relative;
    }

    @media (min-width: 1024px) {
      padding: 40px;
    }
  }

  &__content-wrapper {
    z-index: 2;

    @media (max-width: 1023px) {
      flex-direction: column;
      display: flex;
      align-items: center;
      text-align: center;
      margin-top: -64px;
      padding: 24px 20px 32px;
    }
  }

  &__illustration {
    max-width: 100%;

    @media (min-width: 1024px) {
      position: absolute;
      width: 400px;
      right: 1px;
      z-index: 1;
      height: 99%;
    }
  }

  &__title {
    color: white;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 1.4;
    font-weight: 700;

    @media (min-width: 1024px) {
      font-size: 32px;
    }

    &--green {
      color: $brand-color;
    }
  }

  &__subtitle {
    font-size: 16px;
    margin-top: 8px;
    font-weight: 400;
  }

  &__disable {
    color: $faded-text-color;
    font-size: 14px;
    margin-top: 16px;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  &__partnership {
    display: flex;
    align-items: center;
    margin-top: 2rem;

    &__description {
      font-size: 12px;
      font-weight:500;
      color: white;
    }

    &__logo {
      max-height: 24px;
      margin-left: 8px;
    }
  }

  &__cta {
    margin-top: 2rem;
    width: 100%;

    @media (min-width: 1024px) {
      display: block;
      margin-bottom: 1rem;
      max-width: 250px;
    }
  }

  &__close-icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 16px;
    margin-top: 16px;
    margin-right: 16px;
    opacity: .75;
    z-index: 2;
    cursor: pointer;
  }

  &__pop-terms {
    font-size: 9px;
    color: #7c7c7c;
    max-width: 600px;
    margin-top: 14px;
  }

  &__countdown {
    display: flex;
    margin: 10px 0 -15px;
  }


}