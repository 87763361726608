// Ribbon countdown timer scss

$ribbon-height: 70px;
$ribbon-height-tablet: 50px;
$ribbon-height-desktop: 50px;

.countdown-ribbon-section {
  display: block;
  width: 100%;
  position: sticky;
  height: $ribbon-height;
  top: 0;
  z-index: 200;
  background: #0a1c0e;

  @media (min-width: 778px) {
    height: $ribbon-height-tablet;
  }

  @media (min-width: 1100px) {
    @include dark-bg(false);
    height: $ribbon-height-desktop;
  }

  &.blackfriday-ribbon {
    height: $ribbon-height;

    .blackfriday-ribbon__scroll-btn {
      text-decoration: none;
    }

    &.mob-menu-open {
      background: #000;
    }

    @media (min-width: 778px) {
      height: $ribbon-height-tablet;
    }

    @media (min-width: 1100px) {
      height: $ribbon-height-desktop;
    }

    .countdown-ribbon-text,
    .countdown-ribbon-timer {
      color: #fff;

      &__bold {
        color: #fff;
      }
    }

    .countdown-ribbon-terms {
      font-weight: 300;
      font-size: 13px;
      color: #000;
      cursor: pointer;
      white-space: nowrap;

      @media (min-width: 768px) {
        flex: 1 1 100%;
        text-align: center;
      }

      @media (min-width: 1024px) {
        flex: none;
      }
    }
  }
}

.countdown-ribbon {
  height: $ribbon-height;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 5px;
  flex-wrap: wrap;
  gap: 0 10px;

  @media (min-width: 778px) {
    flex-direction: row;
    flex-wrap: nowrap;
    height: $ribbon-height-tablet;
    gap: 5px;
  }

  @media (min-width: 1100px) {
    flex-direction: row;
    height: $ribbon-height-desktop;
    flex-wrap: nowrap;
    gap: 8px;
    width: 100%;
  }
  @media (min-width: 1200px) {
    //gap: 24px;
  }

  .logo {
    margin-bottom: 10px;

    @media (min-width: 1100px) {
      display: none;
    }

    @media (min-width: 1200px) {
      width: 125px;
      display: block;
      margin-bottom: 5px;
      margin-right: 10px;
    }
  }

  &-text {
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    white-space: normal;
    text-decoration: none;
    color: #fff;

    @media (min-width: 850px) {
      //flex: 0 1 calc(50% - 5px);

      font-size: 14px;
      white-space: nowrap;
    }

    @media (min-width: 1100px) {
      font-size: 16px;
      flex: none;
    }

    &__bold {
      font-weight: 700;
      color: $brand-color;
      display: block;

      @media (min-width: 850px) {
        display: inline-block;
      }
    }
  }

  &-timer {
    display: flex;
    color: #fff;
    justify-content: center;

    &.black-friday-timer {
      //@media (min-width: 850) {
      //  flex: 1 1 calc(50% - 5px);
      //  font-size: 14px;
      //}
      flex: none;
    }

    &__block {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &.seconds {
        display: none;

        //@media (min-width: 850) {
        //  display: inline-flex;
        //}
      }
    }

    &__time {
      border: 1px solid $brand-color;
      border-radius: 8px;
      width: 47px;
      height: 40px;
      line-height: 40px;
      font-weight: 700;
      font-size: 24px;
      text-align: center;
      margin: 0 12px;
    }

    &__unit {
      color: $brand-color;
    }
  }

  .btn-wrap {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
  }
  .btn-ribbon {
    padding: 8px 10px;
    font-size: 14px;
    border: 1px solid #113015;

    @media (min-width: 850px) {
      width: 100%;
      max-width: 100px;
    }
  }
}
