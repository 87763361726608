$brand-color: #4de450;
$primary-text-color: #fff;
$faded-text-color: #7c7c7c;

$brand-xray-color: rgb(#0a1c0e, 0.9);
$card-xray-color: rgb(#202020, 0.9);

$black1-color: #010101;
$black2-color: #020607;
$black3-color: #030303;

$form-error-color: #e54b4b;
