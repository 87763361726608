.vertical-centered {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.screen-section {
  .vertical-centered {
    justify-content: flex-start;
    @media (min-width: 1024px) {
      justify-content: center;
    }
  }
}

.mobile-mx-auto {
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1024px) {
    margin-left: 0;
    margin-right: 0;
  }
}

.flex {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &.col {
    flex-direction: column;
  }

  &.row {
    flex-direction: row;
  }

  @media (min-width: 768px) {
    &.md-row {
      flex-direction: row;
    }

    &.md-col {
      flex-direction: column;
    }
  }

  @media (min-width: 1024px) {
    &.lg-row {
      flex-direction: row;
    }

    &.lg-col {
      flex-direction: column;
    }
  }

  &.centered {
    justify-content: center;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.items-normal {
    align-items: normal;
  }

  &.flex-nowrap {
    flex-wrap: nowrap;
  }

  &.flex-gap-8 {
    gap: 8px;
  }
  &.flex-gap-11 {
    gap: 11px;
  }
  &.flex-gap-12 {
    gap: 12px;
  }

  &.flex-gap-16 {
    gap: 16px;
  }

  &.flex-gap-24 {
    gap: 24px;
  }

  &.flex-gap-32 {
    gap: 32px;
  }

  @media (min-width: 768px) {
    &.md-centered {
      justify-content: center;
    }

    &.md-space-between {
      justify-content: space-between;
    }

    &.flex-nowrap-md {
      flex-wrap: nowrap;
    }
  }

  @media (min-width: 1024px) {
    &.flex-nowrap-lg {
      flex-wrap: nowrap;
    }

    &.lg-space-between {
      justify-content: space-between;
    }

    &.lg-centered {
      justify-content: center;
    }
  }

  &.mobile-reversed {
    @media (max-width: 767px) {
      flex-direction: column-reverse;

      .flex-block-20,
      .flex-block-30,
      .flex-block-40,
      .flex-block-50,
      .flex-block-60,
      .flex-block-70,
      .flex-block-80,
      .flex-block-90,
      .flex-block-100 {
        &:nth-child(n + 2) {
          margin-top: 0;
        }

        &:nth-child(1) {
          margin-top: 24px;

          &.order-1 {
            margin-top: 0;
          }
        }
      }
    }
  }
}

.mt-xs {
  margin-top: 8px;
}

.mb-xs {
  margin-bottom: 8px;
}

.mt-s {
  margin-top: 8px;

  @media (min-width: 1024px) {
    margin-top: 16px;
  }
}

.mb-s {
  margin-bottom: 8px;

  @media (min-width: 1024px) {
    margin-bottom: 16px;
  }
}

.mt-m {
  margin-top: 24px;

  @media (min-width: 1024px) {
    margin-top: 32px;
  }
}

.mb-m {
  margin-bottom: 24px;

  @media (min-width: 1024px) {
    margin-bottom: 32px;
  }
}

.mt-l {
  margin-top: 32px;

  @media (min-width: 1024px) {
    margin-top: 40px;
  }
}

.mb-l {
  margin-bottom: 32px;

  @media (min-width: 1024px) {
    margin-bottom: 40px;
  }
}

.ml-xs {
  margin-left: 8px;
}

.mr-xs {
  margin-right: 8px;
}

.ml-s {
  margin-left: 8px;

  @media (min-width: 1024px) {
    margin-left: 16px;
  }
}

.mr-s {
  margin-right: 8px;

  @media (min-width: 1024px) {
    margin-right: 16px;
  }
}

.ml-m {
  margin-left: 24px;

  @media (min-width: 1024px) {
    margin-left: 32px;
  }
}

.mr-m {
  margin-right: 24px;

  @media (min-width: 1024px) {
    margin-right: 32px;
  }
}

.ml-l {
  margin-left: 32px;

  @media (min-width: 1024px) {
    margin-left: 40px;
  }
}

.mr-l {
  margin-right: 32px;

  @media (min-width: 1024px) {
    margin-right: 40px;
  }
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mt-30 {
  margin-top: 24px;
  @media (min-width: 1024px) {
    margin-top: 30px;
  }
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

@media (min-width: 768px) {
  .d-md-none {
    display: none;
  }

  .d-md-block {
    display: block;
  }

  .d-md-flex {
    display: flex;
  }
}

@media (min-width: 1024px) {
  .d-lg-none {
    display: none;
  }

  .d-lg-block {
    display: block;
  }

  .d-lg-inline-block {
    display: inline-block;
  }

  .d-lg-flex {
    display: flex;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none;
  }

  .d-xl-block {
    display: block;
  }

  .d-xl-inline-block {
    display: inline-block;
  }

  .d-xl-flex {
    display: flex;
  }
  .d-xl-grid {
    display: grid;
  }
}

.w-auto {
  width: auto;
}

.w-100 {
  width: 100%;
}

@media (min-width: 768px) {
  .w-md-auto {
    width: auto;
  }

  .w-md-100 {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .w-lg-auto {
    width: auto;
  }

  .w-lg-100 {
    width: 100%;
  }
}

// flex system

.flex-block-20,
.flex-block-30,
.flex-block-40,
.flex-block-50,
.flex-block-60,
.flex-block-70,
.flex-block-80,
.flex-block-90,
.flex-block-100 {
  width: 100%;
  max-width: 100%;

  &:nth-child(n + 2) {
    margin-top: 24px;

    &.order-1 {
      margin-top: 0;
      margin-bottom: 24px;
    }
  }

  @media (min-width: 768px) {
    &:nth-child(n + 2) {
      margin-top: 0;
    }
  }
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-md-1 {
  @media (min-width: 768px) {
    order: 1;
  }
}

.order-md-2 {
  @media (min-width: 768px) {
    order: 2;
  }
}

.order-md-3 {
  @media (min-width: 768px) {
    order: 3;
  }
}

.order-md-4 {
  @media (min-width: 768px) {
    order: 4;
  }
}

.order-lg-1 {
  @media (min-width: 1024px) {
    order: 1;
  }
}

.order-lg-2 {
  @media (min-width: 1024px) {
    order: 2;
  }
}

.order-lg-3 {
  @media (min-width: 768px) {
    order: 3;
  }
}

.order-lg-4 {
  @media (min-width: 768px) {
    order: 4;
  }
}

@media (min-width: 1024px) {
  .flex-block-20 {
    max-width: 20%;
    flex-grow: 0.2;
  }

  .flex-block-30 {
    max-width: 30%;
    flex-grow: 0.3;
  }

  .flex-block-40 {
    max-width: 40%;
    flex-grow: 0.4;
  }

  .flex-block-50 {
    max-width: 50%;
    flex-grow: 0.5;
  }

  .flex-block-60 {
    max-width: 60%;
    flex-grow: 0.6;
  }

  .flex-block-70 {
    max-width: 70%;
    flex-grow: 0.7;
  }

  .flex-block-80 {
    max-width: 80%;
    flex-grow: 0.8;
  }

  .flex-block-90 {
    max-width: 90%;
    flex-grow: 0.9;
  }

  .flex-block-100 {
    max-width: 100%;
    flex-grow: 1;
  }
}

@media (min-width: 768px) {
  .flex-block-pad-5 {
    padding-left: 5%;
  }

  .flex-block-pad-right-5 {
    padding-right: 5%;
  }

  .flex-block-pad-10 {
    padding-left: 10%;
  }
}

.position-relative {
  position: relative;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.smooth-scroll {
  scroll-behavior: smooth;
}
