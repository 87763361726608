.footer-section {
  // padding: 80px 0 40px;
  padding-bottom: 40px;
}

.footer {
  &__separator {
    width: 100%;
    height: 1px;
    margin-bottom: 32px;
    background: #2d2d2d;
  }

  &__logo {
    display: flex;
    grid-column: 1 / span 4;
    align-items: center;
    margin-bottom: 24px;
    justify-content: center;

    @media (min-width: 768px) {
      margin-bottom: 0;
      grid-column: 1 / span 3;
      justify-content: flex-start;
    }
  }

  &__payments {
    @include responsive-grid-column(6 / span 7, 4 / span 9, 5 / span 5);

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    @media (min-width: 768px) {
      justify-content: initial;
    }

    @media (min-width: 1024px) {
      flex-wrap: nowrap;
      justify-content: flex-end;
    }

    img,
    svg {
      height: 22px;
      margin-right: 16px;
      margin-bottom: 16px;

      @media (min-width: 1024px) {
        margin-bottom: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__iso {
    grid-column: 1 / span 4;
    justify-self: center;
    @media (min-width: 768px) {
      grid-column: 4 / span 9;
      justify-self: flex-end;
    }
  }
}

.footer-nav {
  margin-top: 40px;
  grid-row-gap: 0;
  grid-column-gap: 48px;

  @media (min-width: 1024px) {
    grid-column-gap: $page-column-gap;
  }

  .footer-menu {
    @include responsive-grid-column(0, span 3, 0, span 2);

    &__title {
      font-weight: 600;
      color: $brand-color;
      text-transform: uppercase;
    }

    &__item {
      margin-top: 8px;
    }

    &__link {
      @include hover-underline();
      font-size: 12px;
      color: #fff;
    }
  }

  .best-vps-partner {
    display: flex;
    justify-content: start;

    @media (min-width: 576px) {
      justify-content: center;
    }

    img {
      width: 200px;

      @media (min-width: 576px) {
        width: 200px;
      }
    }
  }
}

.footer-copy {
  &__inner {
    @include responsive-grid-column();

    margin-top: 40px;
    font-size: 9px;
    font-weight: 400;
    color: $faded-text-color;

    a {
      color: inherit;
    }
  }

  &__paragraph {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  &__links {
    color: #ffffff;
    font-size: 12px;
    transition: all .2s ease-in-out;

    .lang-link {
      text-decoration: underline;
      cursor: pointer;
      transition: all .25s ease-in-out;

      &:hover {
        color: $brand-color;
      }
    }
  }
}

