@import 'base';
@import 'blocks/footer';

.htt-header + #scroll-wrap .basic-section:first-child {
  &.next-section-bg-same {
    padding-bottom: var(--small-section-gap);
  }
}

.hero-section {
  &.author-landing-hero {
    padding-top: 180px !important;
  }
  .fp-bg {
    background: $black2-color url('/img/authors/authors-bg.png') no-repeat;
    background-position: right top;
    background-size: auto 100%;

    @include ultrawide-bg-fix;
  }
  .hero-content {
    &__title {
      font-size: 52px;
      line-height: 58px;
    }
  }
}

.author-page-cards {
  background: #202020;
  padding: 16px;
  border-radius: 8px;
  text-decoration: none;
  color: #fff;
  flex: 1 1 100%;
  align-self: stretch;
  @media (min-width: 1024px) {
    flex: 1 1 calc(50% - 32px);
  }

  &__name {
    font-size: 35px;
    font-weight: 700;
    font-family: var(--heading-font);
  }

  &__img {
    border-radius: 50%;
    width: 112px;
    height: 112px;
    margin-right: 16px;
  }

  &__separator {
    margin-top: 16px;
    border-top: 1px solid #efefef;
    border-bottom: none;
    border-left: none;
    border-right: none;
  }
}

.author-cards {
  display: flex;
  align-items: center;
  gap: 16px;
  box-shadow: 0 0 10px #adadad;
  padding: 24px;
  text-decoration: none;

  &.heading {
    box-shadow: none;
  }

  &__img {
    border-radius: 50%;
    width: 112px;
    height: 112px;
  }

  &__name {
    font-size: 32px;
    font-weight: 700;
    text-decoration: none;

    @media (min-width: 1024px) {
      font-size: 42px;
    }
  }

  &__text {
    color: #000;
    text-decoration: none;
  }

  &__linkedin-icon {
    color: $brand-color;
    margin-right: 5px;
  }
}

.dot-list {
  list-style-type: disc;
  display: block;
  padding-left: 20px;

  li {
    display: list-item;

    &::before {
      content: none;
      color: #fff;
    }
  }
}

.editorial-guidelines-section {
  .guidelines-container {
    display: flex;
    gap: 60px;
    flex-wrap: wrap;
    .guideline {
      flex: 1 1 100%;
      @media (min-width: 768px) {
        flex: 1 1 calc(50% - 60px);
      }
    }
  }
}

.do-this-container {
  display: flex;
  gap: 60px;
  flex-wrap: wrap;
}
.do-this {
  flex: 1 1 100%;
  @media (min-width: 768px) {
    flex: 1 1 calc(25% - 60px);
  }
  display: flex;
  align-items: center;
  padding: 40px;
  text-decoration: none;
  font-weight: 500;
  color: #fff;
  background: #202020;
  border-left: 4px solid $brand-color;
  border-radius: 8px 8px 0 0;
  justify-content: space-between;

  @media (min-width: 768px) {
    flex-direction: column;
    justify-content: center;
    border-bottom: 4px solid $brand-color;
    border-left: none;
  }

  &__icon {
    color: $brand-color;

    &:first-child {
      margin-right: 16px;

      @media (min-width: 768px) {
        margin-right: 0;
      }
    }
  }

  &:hover {
    background: $brand-color;
    color: #fff;

    .do-this__icon {
      color: #fff;
    }
  }
}
